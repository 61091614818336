<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div class="principle-dd" v-if="this.actionID == 'add'">
        <label for="principleSelect" class="form-label page-heading-dropdown lable-text"> Principle </label>
        <select class="form-control page-heading-dropdown-content principle-select" id="principleSelect"
          v-model="principleId" @change="(onchangePrinciple = 'principle'), principleDetails()">
          <option disabled value="-1">Select Principle</option>
        </select>
      </div>
      <div class="principle-dd" v-else>
        <label for="principleSelect" class="form-label page-heading-dropdown lable-text"> Principle </label>
        <select class="form-control page-heading-dropdown-content principle-select" id="principleSelect"
          v-model="principleId" @change="(onchangePrinciple = 'principle'), principleDetails()" disabled>
          <option disabled value="-1">Select Principle</option>
        </select>
      </div>
      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%" @change="handleRadioChange($event)">
            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0"
              :class="{ cursorNotAllowed: actionID !== 'add' && levelId != 0 }">
              <label class="container">
                Level 0
                <input type="radio" class="cursorpointer" name="some-radios" value="0" style="margin-right: 1rem"
                  :disabled="actionID !== 'add' && levelId != 0" :class="{
                      cursorNotAllowed: actionID !== 'add' && levelId != 0
                    }" @change="(levelId = 0), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1"
              :class="{ cursorNotAllowed: actionID !== 'add' && levelId != 1 }">
              <label class="container">
                Level 1
                <input type="radio" class="cursorpointer" name="some-radios" value="1" style="margin-right: 1rem"
                  :disabled="actionID !== 'add' && levelId != 1" :class="{
                      cursorNotAllowed: actionID !== 'add' && levelId != 1
                    }" @change="(levelId = 1), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2"
              :class="{ cursorNotAllowed: actionID !== 'add' && levelId != 2 }">
              <label class="container">
                Level 2
                <input type="radio" class="cursorpointer" name="some-radios" value="2" style="margin-right: 1rem"
                  :disabled="actionID !== 'add' && levelId != 2" :class="{
                      cursorNotAllowed: actionID !== 'add' && levelId != 2
                    }" @change="(levelId = 2), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3"
              :class="{ cursorNotAllowed: actionID !== 'add' && levelId != 3 }">
              <label class="container">
                Level 3
                <input type="radio" class="cursorpointer" name="some-radios" value="3" style="margin-right: 1rem"
                  :disabled="actionID !== 'add' && levelId != 3" :class="{
                      cursorNotAllowed: actionID !== 'add' && levelId != 3
                    }" @change="(levelId = 3), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4"
              :class="{ cursorNotAllowed: actionID !== 'add' && levelId != 4 }">
              <label class="container">
                Level 4
                <input type="radio" class="cursorpointer" name="some-radios" value="4" style="margin-right: 1rem"
                  :disabled="actionID !== 'add' && levelId != 4" :class="{
                      cursorNotAllowed: actionID !== 'add' && levelId != 4
                    }" @change="(levelId = 4), handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" style="border-radius: 0 4px 4px 0"
              :class="{ cursorNotAllowed: actionID !== 'add' && levelId != 5 }">
              <label class="container">
                Level 5
                <input type="radio" class="cursorpointer" name="some-radios" value="5" style="margin-right: 1rem"
                  :disabled="actionID !== 'add' && levelId != 5" :class="{
                      cursorNotAllowed: actionID !== 'add' && levelId != 5
                    }" @change="(levelId = 5), handleRadioChange($event.target.value)" />
              </label>
            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name">
          {{ principleArr[0] + "." + levelId + " " + principleArr[1] }}
        </div>
        <div class="page-content-regular prp-name">
          <pre class="page-content-regular">{{ this.practiceDescription }}</pre>
        </div>
        <div class="module-title prp-name margin-top-1em">Guidelines</div>
        <div class="behavoir-des page-content-regular" id="guidelineDescription">
          {{ this.guidelineDesc }}
        </div>
      </div>
      <div class="attch-con">
        <div class="module-title prp-name margin-top-1em">Attachments</div>
        <div class="row attch-row">
          <ul class="col-3 uploaded-items attch-col page-content-regular">
            <li class="uploaded-items-li" v-for="(eachattachment, i) in attachmentUrls" :key="i">
              <span class="uploaded-file-name">
                <a class="li-doc-class" @click="downloadItem(eachattachment)" :download="eachattachment.documentURL">{{
                  eachattachment.documentName + "." + eachattachment.documentType }}
                </a>
              </span>
              <img alt="download" class="attch-icon" src="../../../assets/download 2.svg" />
            </li>
          </ul>
        </div>
      </div>
      <div class="prp-info top-padding">
        <div class="module-title prp-name">Action Information</div>
        <div class="action-info-block">
          <div class="col-md-8 no-padding">
            <label for="observedCondition" class="form-label page-heading-dropdown lable-text normal-text"> Action Details
            </label>
            <textarea class="page-content-regular action-desc" id="observedCondition" name="observedCondition" rows="6"
              cols="105" ref="actionDetailTA" v-model="actionDetails"></textarea>
          </div>
          <div class="col-md-4">
            <SearchOwner :user="ownerList" :editUser="edituser" @userSelected="userSelected($event)"
              class="form-control page-heading-dropdown-content principle-select" style="border: transparent; padding: 0">
            </SearchOwner>
          </div>
        </div>
      </div>
      <div class="prp-info" style="display: flex; justify-content: space-between">
        <div class="col-md-8 no-padding">
          <div class="module-title prp-name">Schedule Information</div>
          <div class="schedule-info-block">
            <div>
              <label id="action-completion-date-label" for="action-completion-date-input"
                class="form-label page-heading-dropdown lable-text normal-text"> Committed Date </label>

              <b-input-group class="calender-input">
                <b-form-input id="action-completion-date-input" v-model="actionCompletionDateInput" type="text"
                  ref="actionCompletionDateIF" placeholder="Committed Date" autocomplete="off" disabled
                  class="input-target-completion-date"></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="target-completion-date-setting" v-model="actionCompletionDate" button-only right
                    locale="en-US" aria-controls="action-completion-date-input" dark :min="minDate"
                    :disabled="committedDisabled" label-no-date-selected="Committed Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      }" @context="onContextT">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-6">
              <label id="action-actual-date-label" for="action-actual-date-input"
                class="form-label page-heading-dropdown lable-text normal-text"> Actual Completion Date </label>
              <b-input-group class="calender-input">
                <b-form-input id="action-actual-date-input" v-model="actionActualDateInput" type="text"
                  ref="actionActualDateIF" placeholder="Actual Completion Date" autocomplete="off" disabled
                  class="input-target-completion-date"></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="target-completion-date-setting" v-model="actionActualDate" button-only right
                    locale="en-US" aria-controls="action-actual-date-input" dark :min="minDate"
                    label-no-date-selected="Actual Completion Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      }" @context="onContextA">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="col-md-4 no-padding other-block">
          <div class="module-title prp-name">Other</div>
          <div>
            <label for="file-input" class="form-label page-heading-dropdown lable-text normal-text"> Attachments ( Max.
              allowed 10 MB ) </label>

            <div class="fileinputs calender-input">
              <input id="file-input" type="file" class="file calender-input" placeholder="NO"
                @change="(e) => onChangeListener(e.target)" /><!-- accept="image/*" -->
              <div class="fakefile">
                <input id="file-name" class="fakefileinput" placeholder="No file chosen" />
                <span class="fakefilespan">Browse</span>
              </div>
            </div>
            <ul class="uploaded-items">
              <li class="uploaded-items-li" v-for="documentDetail in documentDetailsArr" :key="documentDetail.index">
                <span class="uploaded-file-name">
                  <a
                    @click="downloadItem({ documentUrl: documentDetail.message, documentName: nameFormatter(documentDetail) })">
                    {{ nameFormatter(documentDetail) }}
                  </a>
                </span>
                <img alt="more" class="attch-icon" src="../../../assets/bin.svg"
                  @click="removeAttachment(documentDetail.index)" />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="add-action-footer-line"></div>
      <div class="add-action-footer-info">
        <b-button v-b-modal.targetSetTargetYearModal class="discard-btn" size="lg" @click="discardAction()"> Discard
        </b-button>
        <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="onSubmit()"> Submit
        </b-button>
      </div>
    </div>
    <MessagePopup :routToBack="routToBack" class="alert" :message="errorMsg" :show="showPopup" :headText="headText" />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import ApiCalls from "../../../common/api/apiCalls";
import Api from "../../../common/constants/api";
import SearchOwner from "../ActionTracker/SearchOwner.vue";
import Loader from "../../../components/common/Loader.vue";
import MessagePopup from "@/components/common/MessagePopup.vue";
import Role from "../../../common/constants/roles";
import moment from "moment";
import axios from "axios";
export default {
  name: "AddNewAction",
  components: {
    Breadcrumb,
    Loader,
    SearchOwner,
    MessagePopup
  },
  data() {
    return {
      roles: Role,
      routToBack: false,
      isUserAllowedToCalibrateAction: "N",
      errorMsg: "",
      headText: "",
      showPopup: false,
      pillars: [],
      extfile: "",
      view_action_attachments: [],
      edituser: "",
      editUse: "",
      showLoader: true,
      principleId: 1,
      //minDate: new Date(),
      minDate: "",
      plantId: 1,
      practiseDetailsData: [],
      attachmentUrls: [],
      targetLevel: 0,
      pillarId: 1,
      practiceId: 1,
      selected: 0,
      completedSteps: 75,
      totalSteps: 100,
      ownerList: [],
      ownerObj: "",
      pillarName: "",
      plantName: "",
      actionDetails: "",
      actionCompletionDateInput: "",
      actionCompletionDate: "",
      actionActualDateInput: "",
      actionActualDate: "",
      uploadFile: "",
      currentPrinciple: "",
      currentPrincipleDesc: "-",
      behavoiursDes: "-",
      documentIndex: 0,
      timeStamp: "",
      documentDetailsArr: [],
      practiceDescription: "-",
      actionID: "",
      levelId: "",
      uploadFlag: false,
      statusId: "",
      principleArr: [],
      guidelineDesc: "",
      principleList: [],
      onchangePrinciple: "",
      committedDisabled: false,
      allpillarId: 0
    };
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 3
    });
    this.$store.dispatch("breadcrumb", {
      title: "Add New Action",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Maturity Model Assessment / ",
          redirect: "/assessment",
          primary: true
        },
        {
          name: "Take Assessment / ",
          redirect: "/take-assessment",
          primary: true
        },
        { name: "Add New Action", primary: false }
      ]
    });
  },
  created() {
    // /:plantId/:pillarId/:principleId/:practiceId
    console.log(this.$route.params);
    this.plantId = Number(this.$route.params.plantId);
    this.principleId = Number(this.$route.params.principleId);
    this.practiceId = Number(this.$route.params.practiceId);
    this.actionID = this.$route.params.actionID;
    this.levelId = Number(this.$route.params.levelId);
    this.pillarName = this.$route.params.pillarName;
    this.plantName = this.$route.params.plantName;
    this.statusId = this.$route.params.statusId;
    let pillar_Id = this.$route.params.pillarId.toString();

    let isAllPillar = pillar_Id.indexOf("&");
    if (isAllPillar === -1) {
      this.pillarId = Number(this.$route.params.pillarId);
    } else {
      let indx = pillar_Id.indexOf("&");
      this.allpillarId = pillar_Id.substring(indx + 1);
      this.pillarId = Number(pillar_Id.substring(0, indx));
    }
    this.getPrincipleData();
    this.getTargetDate();
    this.getOwnerList();

    this.timeStamp = new Date().toISOString();

    let currentPath = this.actionID !== "add" ? "Edit Action" : "Add New Action";
    let addNewAction = " Item: " + this.pillarName;
    this.$store.dispatch("breadcrumb", {
      title: currentPath + addNewAction,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Maturity Model Assessment / ",
          redirect: "/assessment",
          primary: true
        },
        {
          name: "Take Assessment / ",
          redirect: "/take-assessment",
          primary: true
        },
        { name: currentPath, primary: false }
      ]
    });
  },
  computed: {
    // updatedTimestamp
  },
  methods: {
    nameFormatter(documentDetail) {
      return (
        decodeURIComponent(documentDetail.message).split("/").pop().split("_$^#^$_")[0] +
        decodeURIComponent(documentDetail.message).split("/").pop().split("_$^#^$_")[decodeURIComponent(documentDetail.message).split("/").pop().split("_$^#^$_").length - 1]
      );
    },
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj.documentUrl));
      const label = JSON.parse(JSON.stringify(obj.documentName));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })
    },
    checkUserProfileAndDisableCommittedDate() {
      console.log("this.$oidc.userProfile.roles:");
      console.log(this.$oidc.userProfile.roles);
      console.log("this.$oidc.userProfile.email:");
      console.log(this.$oidc.userProfile.email);
      console.log("this.updatedTimestamp");
      console.log(this.updatedTimestamp);

      let currentUserEmail = this.$oidc.userProfile.email;
      ApiCalls.httpCallDetails(Api.MYPROFILE + currentUserEmail, "get").then((data) => {
        this.myDetails = data.data;
        this.isUserAllowedToCalibrateAction = this.myDetails.allowCalibrate;
        this.disableCommitedDate();
      });
    },
    // disableCommitedDate() {
    //   let date = moment(new Date(this.updatedTimestamp)).format("DD-MM-YYYY");
    //   let today = moment(new Date()).format("DD-MM-YYYY");
    //   console.log(today, date, moment(today).isSameOrAfter(date));
    //   this.committedDisabled = !moment(today).isSameOrAfter(date);
    // },
    disableCommitedDate() {
      console.log("disableCommitedDate > isUserAllowedToCalibrateAction: " + this.isUserAllowedToCalibrateAction);
      console.log("disableCommitedDate > updatedTimestamp: " + this.updatedTimestamp);
      let date = moment(new Date(this.updatedTimestamp)).format("YYYY-MM-DD");
      let today = moment(new Date()).format("YYYY-MM-DD");
      console.log("today: " + today, "date: " + date, "moment: " + moment(date).isSameOrAfter(today));
      //if(this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)){
      if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.isUserAllowedToCalibrateAction == "Y") {
        this.minDate = "2019-01-01";
        this.committedDisabled = false
      } else {
        this.minDate = new Date();
        this.committedDisabled = !moment(date).isSameOrAfter(today);
      }
    },
    userSelected(evt) {
      this.ownerObj = evt;
    },
    onContextT(ctx) {
      this.actionCompletionDateInput = ctx.selectedFormatted;
    },
    onContextA(ctx) {
      this.actionActualDateInput = ctx.selectedFormatted;
    },
    onSubmit() {
      let docArray = [];

      if (this.uploadFlag === true) {
        this.documentDetailsArr.forEach((element) => {
          let url = element.message;
          let obj = {
            documentUrl: url,
            documentType: url.substring(url.lastIndexOf(".") + 1)
            // active:active,
            // actionDocumentsId: actionDocumentsId,
            // documentName:"file"
          };

          docArray.push(obj);
          this.uploadFlag = false;
        });
      } else {
        docArray = this.documentDetailsArr;
      }

      this.showPopup = false;
      if (this.actionID == "add") {
        if ((this.actionCompletionDate == "" || this.actionCompletionDate == null) && this.committedDisabled) {
          this.errorMsg = "Action can not be added as the forecast setting date is passed.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else if (this.ownerObj == -1) {
          this.errorMsg = "Please select owner.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else if (this.actionDetails.length == 0 || this.actionDetails == "" || this.actionDetails == null) {
          this.errorMsg = "Please enter some text in action details.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else if ((this.actionCompletionDate == "" || this.actionCompletionDate == null) && !this.committedDisabled) {
          this.errorMsg = "Please select committed date.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else {
          let requestPayload = {
            actionDescription: this.actionDetails,
            targetCompletionDate: this.actionCompletionDate ? this.actionCompletionDate : null,
            actualCompletionDate: this.actionActualDate ? this.actionActualDate : null,
            principleId: Number(this.principleId),
            pillarId: this.pillarId,
            plantId: this.plantId,
            practiceId: this.practiceId,
            ownerUserId: this.ownerObj,
            documentDetails: docArray
            // levelId: this.levelId,
          };
          this.showLoader = true;

          ApiCalls.httpCallDetails(Api.CREATEADDACTION, "post", requestPayload).then((data) => {
            this.showLoader = false;
            if (this.$route.params.name == "editAction") {
              this.$router.push("/action/edit-action/" + this.plantId + "/" + this.pillarId);
            } else {
              this.$router.push(
                "/take-assessment/" + this.plantId + "/" + this.pillarId + "/" + this.pillarName + "/action" + "/" + this.plantName + "/" + this.levelId + "/" + this.principleId
              );
            }
          });
        }
      } else {
        if ((this.actionCompletionDate == "" || this.actionCompletionDate == null) && this.committedDisabled) {
          this.errorMsg = "Action can not be added as the forecast setting date is passed.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else if (this.ownerObj == -1) {
          this.errorMsg = "Please select owner.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else if (this.actionDetails.length == 0) {
          this.errorMsg = "Please enter some text in action details.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else if ((this.actionCompletionDate == "" || this.actionCompletionDate == null) && !this.committedDisabled) {
          this.errorMsg = "Please select committed date.";
          this.headText = "";
          this.showPopup = true;
          this.routToBack = false;
        } else {
          let requestPayload = {
            actionDescription: this.actionDetails,
            targetCompletionDate: this.actionCompletionDate ? this.actionCompletionDate : null,
            actualCompletionDate: this.actionActualDate ? this.actionActualDate : null,
            principleId: this.principleId,
            pillarId: this.pillarId,
            plantId: this.plantId,
            actionId: parseInt(this.actionID),
            practiceId: this.practiceId,
            ownerUserId: this.ownerObj,
            documentDetails: docArray,
            comments: "Sample1",
            revisedCompletionDate: "2022-09-30T00:00:00",
            statusId: parseInt(this.statusId),
            levelId: this.levelId
          };

          if (this.actionDetails.length !== 0 && this.ownerObj != -1) {
            this.showLoader = true;

            ApiCalls.httpCallDetails(Api.UPDATEACTION, "put", requestPayload).then((data) => {
              this.showLoader = false;
              if (this.$route.params.name == "editAction") {
                this.$router.push("/action/edit-action/" + this.plantId + "/" + this.pillarId);
              } else if (this.$route.params.name == "Dashboard-action") {
                this.$router.go(-1);
              } else {
                this.$router.push(
                  "/take-assessment/" +
                  this.plantId +
                  "/" +
                  this.pillarId +
                  "/" +
                  this.pillarName +
                  "/action" +
                  "/" +
                  this.plantName +
                  "/" +
                  this.levelId +
                  "/" +
                  this.principleId
                );
              }
            });
          }
        }
      }
    },
    getOwnerList() {
      //   GETOWNERLIST
      ApiCalls.httpCallDetails(Api.GETOWNERLIST, "get").then((dataJSON) => {
        if (dataJSON.success) {
          this.ownerList = dataJSON.data;

          this.ownerObj = "-1";
          if (this.actionID !== "add") {
            this.getViewActionItems();
          }
          this.showLoader = false;
        }
      });
    },
    getTargetDate() {
      ApiCalls.httpCallDetails(Api.TARGETCOMPLETIONDATEGET + this.plantId, "get").then((dataJSON) => {
        // this.updatedTimestamp = new Date(data[0]targetLevelCompletionDate);
        let data = dataJSON.data[0];

        if (data) {
          console.log(data.targetLevelCompletionDate);
          this.updatedTimestamp = new Date(data.targetLevelCompletionDate);
          //this.disableCommitedDate();
          this.checkUserProfileAndDisableCommittedDate();
        }
      });
    },
    principleDetails() {
      let selectobj = document.getElementById("principleSelect");
      let text = selectobj.options[selectobj.selectedIndex].text;

      this.principleArr = text.split(". ");
      ApiCalls.httpCallDetails(Api.PRACTISEDETAILS + selectobj.value + "/" + this.plantId, "get").then((dataJSON) => {
        if (dataJSON.success) {
          this.currentPrincipleDesc = dataJSON.data.principleDescription;
          this.practiseDetailsData = dataJSON.data.practiceList;

          this.targetLevel = dataJSON.data.principleTargetMaturityLevel;
          if (this.onchangePrinciple == "principle") {
            this.handleRadioChange(dataJSON.data.principleTargetMaturityLevel);
            this.onchangePrinciple = "";
          } else {
            this.handleRadioChange(this.levelId);
          }
        }
      });
    },
    discardAction() {
      if (this.allpillarId == -1) {
        this.pillarId = this.allpillarId;
      }

      if (this.$route.params.name == "editAction") {
        this.$router.push("/action/edit-action/" + this.plantId + "/" + this.pillarId);
      } else if (this.$route.params.name == "Dashboard-action") {
        this.$router.go(-1);
      } else {
        this.$router.push(
          "/take-assessment/" + this.plantId + "/" + this.pillarId + "/" + this.pillarName + "/action" + "/" + this.plantName + "/" + this.levelId + "/" + this.principleId
        );
      }

      /**    this.plantId = Number(this.$route.params.plantId);
this.pillarId = Number(this.$route.params.pillarId);
this.principleId = Number(this.$route.params.principleId);
this.practiceId = Number(this.$route.params.practiceId);
this.actionID = this.$route.params.actionID;
this.levelId = Number(this.$route.params.levelId); */
    },
    onChangeListener(value) {
      if (value.files[0] && value.files[0].size < 10485760) {
        document.querySelector("#file-name").value = value.files[0].name;
        let reader = new FileReader();
        reader.readAsDataURL(value.files[0]);
        reader.onloadend = () => {
          let imageSet = [];
          imageSet.push(reader.result);
          let newImageSet = [];
          newImageSet.push(value.files[0]);
          let index = this.documentIndex++;
          let str = value.files[0].name;
          let fileName = str.substring(str.lastIndexOf("."), 0) + "_$^#^$_" + this.timeStamp + "_$^#^$_." + value.files[0].name.split(".")[1];
          let fileUploadFormData = new FormData();
          fileUploadFormData.append("imgsrc", newImageSet[0], fileName);
          this.showLoader = true;

          ApiCalls.httpCallDetails(Api.CREATEDOCUMENT, "post", fileUploadFormData).then((data) => {
            this.uploadFlag = true;
            this.showLoader = false;
            if (data.message) {
              data.index = index;
              let url = data.message;
              data.format = url.substring(url.lastIndexOf(".") + 1);
              this.documentDetailsArr.push(data);
              document.querySelector("#file-name").value = "";
            }
          });
        };
      } else {
        console.warn("file is larger than 10MB");
      }
    },
    handleRadioChange(id) {
      for (let i = 0; i <= this.targetLevel; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.add("live-level");
      }
      for (let i = 0; i <= 5; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.remove("active-level");
      }
      this.guidelineDesc = this.practiseDetailsData[parseInt(id)].guideline ? this.practiseDetailsData[parseInt(id)].guideline : "";
      this.practiceDescription = this.practiseDetailsData[parseInt(id)].practiceDescription ? this.practiseDetailsData[parseInt(id)].practiceDescription : "";
      this.attachmentUrls = this.practiseDetailsData[parseInt(id)].attachmentUrls;

      let radioElement = document.getElementById("Level" + id);
      radioElement.classList.add("active-level");
      this.levelId = this.practiseDetailsData[parseInt(id)].levelId;
      this.practiceId = this.practiseDetailsData[parseInt(id)].practiceId;
    },
    removeAttachment(index) {
      for (let i = 0; i < this.documentDetailsArr.length; i++) {
        if (this.documentDetailsArr[i].index == index) {
          this.documentDetailsArr[i].active = "N";
          this.documentDetailsArr.splice(i, 1);
        }
      }
    },
    async getPrincipleData() {
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + this.pillarId, "get").then((dataJSON) => {
        let data = dataJSON.data;
        this.principleList = dataJSON.data;
        for (let el of data) {
          let principleid = el.principleId;
          let principleName = el.principleName;
          let principleNumber = el.principleNumber;
          let element = document.createElement("OPTION");
          element.disabled = el.active === "Y" ? false : true;
          element.setAttribute("value", principleid);
          let t = document.createTextNode(principleNumber + ". " + principleName);
          element.appendChild(t);
          document.getElementById("principleSelect").appendChild(element);
        }
        let selectobj = document.getElementById("principleSelect");
        // // selectobj.value = data[0].principleId;
        selectobj.value = this.principleId;
        this.principleDetails();
      });
    },
    getViewActionItems() {
      if (this.plantId != 0 && this.actionID != 0) {
        let url = Api.GETVIEWACTIONITEMS + this.actionID + "/" + this.plantId;

        ApiCalls.httpCallDetails(url, "get").then((data) => {
          if (data.success === true) {
            let index = 0;
            this.view_action_attachments = data.data.attachments;
            this.view_action_attachments.map((element) => {
              element.index = ++index;
              let url = element.documentUrl;
              element.format = url.substring(url.lastIndexOf(".") + 1);
              element.message = url;
              this.documentDetailsArr.push(element);
            });

            this.ownerList.forEach((element) => {
              if (element.userId == data.data.ownerUserId) {
                this.ownerObj = element.userId;
                this.edituser = element.userName;
              }
            });

            this.actionDetails = data.data.actionDescription ? data.data.actionDescription : "";
            let ind = this.ownerList.findIndex((val) => val.userId == data.data.ownerUserId);
            if (ind === -1) {
              this.ownerObj = "-1";
            } else {
              this.ownerObj = data.data.ownerUserId;
            }
            this.actionCompletionDate = data.data.targetCompletionDate ? data.data.targetCompletionDate : "";
            this.actionActualDate = data.data.actualCompletionDate ? data.data.actualCompletionDate : "";
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.cursorNotAllowed {
  cursor: not-allowed !important;
  opacity: 0.3 !important;
}

.progress-con {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.per-text {
  color: #0f7222;
  font-weight: 600;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.asmt-info {
  border-bottom: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  align-items: center;
}

.asmt-padding {
  margin-top: 1em;
  margin-bottom: 1em;
}

.add-action-footer-line {
  width: 98%;
  margin: 12px 0px;
  margin-left: 1%;
  border-top: 1px solid #707070b6;
}

.add-action-footer-info {
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.principle-dd {
  width: 30%;
  padding: 2rem;
  padding-bottom: 0;
}

.principle-select {
  height: 4.4rem;
  border-color: #313131;
}

.level-con {
  padding: 1em;
  /* margin-top: 1em; */
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  /* padding-top: 1em;
  padding-bottom: 1em; */
  border-radius: 6px;
}

.live-level {
  background: #eff3ff;
}

.cursorpointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.active-level {
  /* background: #006400; */
  background: #00428f !important;
  color: #fff;
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #00428f !important;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.margin-top-1em {
  margin-top: 1em;
}

.behavoir-des {
  margin-top: 3px;
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

.attch-col {
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  color: #274ba7;
}

.attch-row {
  padding-left: 1em;
  padding-right: 2em;
  margin-top: 5px;
}

.attch-icon {
  float: right;
}

.review-con {
  padding-left: 2em;
  padding-right: 2em;
}

.review-rox {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.review-col {
  border: 2px solid #e2dfdf;
  border-radius: 6px;
  padding: 1em;
}

.pm-rw-con {
  margin-right: 71px;
}

.review-row {
  padding: 1em;
}

.review-type-col {
  text-align: center;
  background: #f0f7ff;
  border-radius: 6px;
  padding: 1em;
  margin-right: 10px;
}

.rw-act-con {
  text-align: right;
  margin-top: 10px;
}

.cancel-btn {
  border: 0px !important;
}

.cancel-btn:hover {
  background: #fff;
  color: #274ba7;
}

.cancel-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.save-btn {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.save-btn:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.cancel-btn:active {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.save-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.normal-text {
  color: #313131;
}

.action-desc {
  border: #313131 1px solid;
  border-radius: 4px;
  padding: 1rem;
}

.module-title {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-bottom: 9px;
}

.no-padding {
  padding: 0;
}

.action-info-block {
  display: flex;
}

.schedule-info-block {
  display: flex;
  justify-content: space-between;
}

.other-block {
  display: grid;
  justify-content: end;
}

.calender-input {
  height: 52px;
  width: 29.75rem;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

/* calender */
.input-target-completion-date {
  border-color: #313131;
  padding: 4px;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

/* calender */
.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:active {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
  font-family: "BarlowM", sans-serif !important;
  font-size: 1.166rem !important;
  margin-right: 2rem !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.9rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.uploaded-file-name {
  font: 1.166rem "BarlowM", sans-serif;
  color: #274ba7;
  margin-right: 16px;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}

.lable-text {
  z-index: 2;
}

div.fileinputs {
  position: relative;
  border: 1px solid #313131;
  border-radius: 4px;
}

div.fakefile {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fakefileinput {
  height: 100%;
  width: 80%;
  border: transparent;
  border-right: 1px solid #313131;
  color: #313131;
  font: 1.166rem "BarlowR", sans-serif;
  padding: 1rem;
  margin: 2px;
}

.fakefilespan {
  text-align: center;
  width: 20%;
  font: 1.166rem "BarlowSb", sans-serif;
  color: #274ba7;
}

input.file {
  position: relative;
  text-align: right;
  /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
  opacity: 0;
  z-index: 2;
}

.top-padding {
  padding-top: 1rem;
}

#action-completion-date-input:disabled {
  background: transparent;
  padding: 1rem;
}

#action-actual-date-input:disabled {
  background: transparent;
  padding: 1rem;
}

/* ------------radio btn ------------*/
.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* ------------radio btn ------------*/

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.6rem;
  left: 0.6rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}

.styled-input {
  width: 33.3333%;
  position: relative;
}

.styled-input label {
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 100%;
}

.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

.review-box {
  width: 100%;
  border-radius: 6px;
  height: 5em;
  resize: none;
  padding: 1rem 1rem;
  border: 0;
  font-size: 1rem;
}

.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
}
</style>
